<template>
  <v-card
    tile
    :loading="loading ? 'grey lighten-1' : false"
    :disabled="loading"
  >
    <v-toolbar elevation="1" dense>
      <v-toolbar-title>
        <span class="text-caption" v-text="'INFORMACIÓN GENERAL'" />
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn icon @click.prevent="show_info = !show_info">
          <v-icon v-text="`mdi-chevron-${show_info ? 'up' : 'down'}`" />
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text v-if="rs && !loading && show_info">
      <v-row dense>
        <v-col cols="12" v-if="rs.discharged">
          <v-alert type="success" dense>
            <div class="text-center">
              La solicitud fue terminada el  {{ rs.discharged }}
            </div>
          </v-alert>
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <ViewData label="Folio" :value="rs.folio" />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <ViewData label="Fecha" :value="rs.created_at" />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <ViewData label="Aseguradora" :value="rs.insurance.name" />
        </v-col>
        <v-col
          cols="12"
          xs="12"
          md="3"
          :class="rs.insured_id ? '' : 'orange--text text--darken-3'"
        >
          <ViewData label="ID SM / Póliza" :value="rs.enrollment" />
        </v-col>
        <v-col
          cols="12"
          xs="12"
          md="3"
          :class="rs.insured_id ? '' : 'orange--text text--darken-3'"
        >
          <ViewData
            label="Asegurado"
            :value="`${rs.name} ${rs.first_surname} ${rs.second_surname}`"
          />
        </v-col>
        <v-col
          cols="12"
          xs="12"
          md="3"
          :class="rs.insured_id ? '' : 'orange--text text--darken-3'"
        >
          <ViewData label="Contratante" :value="rs.contractor" />
        </v-col>
        <v-col
          cols="12"
          xs="12"
          md="3"
          :class="
            rs.accidente !== null && rs.initial !== null
              ? ''
              : 'orange--text text--darken-3'
          "
        >
          <ViewData
            label="Seguimiento médico"
            :value="rs.accidente !== null && rs.initial !== null ? 'SI' : 'NO'"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Axios from "axios";
import ViewData from "./ViewData.vue";
import { headersToken, URL_API } from "../control";

export default {
  components: {
    ViewData,
  },
  data() {
    return {
      login: this.$store.getters.getLogin,
      rs_id: this.$attrs.rs_id,
      rs: null,
      show_info: true,
      loading: true,
    };
  },
  methods: {},
  mounted() {
    Axios.get(
      URL_API + `/rss/${this.rs_id}/3`,
      headersToken(this.login.token)
    ).then((response) => {
      this.rs = response.data.data;
      this.loading = false;
    });
  },
};
</script>